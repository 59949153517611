import { Grid, Image } from 'antd';
import { HeaderContainer } from './HeaderStyle';
const { useBreakpoint } = Grid;

function Header() {
  const { md, lg } = useBreakpoint();

  return (
    <HeaderContainer
      style={{
        background: '#fff',
        lineHeight: md ? '90px' : '64px',
        height: md ? '90px' : '64px',
        justifyContent: 'center',
      }}
    >
      <a href="/sign-in">
        <div className="logo-public">
          <Image
            width={lg ? 240 : 220}
            preview={false}
            src="/logo/fa-logo.png"
            alt="logo"
          />
        </div>
      </a>
    </HeaderContainer>
  );
}

export default Header;
