import { Drawer, Grid, Image } from 'antd';
import HamburgerMenu from 'common/sharedComponents/Menu/HamburgerMenu';
import Menu from 'common/sharedComponents/Menu/Menu';
import SwitcherLanguage from 'features/SwitcherLanguage';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import logo from '../../assets/images/fairatmos-logo-white.png';
import { HeaderContainer } from './HeaderStyle';
const { useBreakpoint } = Grid;

function HeaderProtectedPage(props) {
  const { lg } = useBreakpoint();
  const [visible, setVisible] = useState(false);
  const { user } = useSelector((state) => state.authentication);
  const { data } = useSelector((state) => state.project.projectList);

  const onClose = () => {
    setVisible(false);
  };

  const handleToggleMenu = (active) => {
    setVisible(active);
  };

  const handleShadowNav = () => {
    let val = null;
    if (visible) {
      return val;
    } else {
      if (data?.length) {
        val = '0px 2px 8px rgba(0, 0, 0, 0.15)';
      } else {
        val = 'rgb(0 0 0 / 5%) 0px 1px 1px';
      }
    }
    return val;
  };

  return (
    <HeaderContainer
      style={{
        height: lg ? '90px' : '64px',
        boxShadow: handleShadowNav(),
        justifyContent: 'space-between',
      }}
    >
      <div style={{ marginLeft: 15 }}>
        <a href="/">
          <Image width={lg ? 180 : 140} preview={false} src={logo} alt="logo" />
        </a>
      </div>
      {!lg && (
        <div>
          <SwitcherLanguage />
        </div>
      )}
      {!props.hideMenu && (
        <div className="header__container">
          <div className="header_right">
            <Menu isProtectedPage onClickMenu={handleToggleMenu} user={user} />
          </div>
          {!lg && (
            <>
              <HamburgerMenu
                background={'#fff'}
                open={visible}
                onClick={handleToggleMenu}
              />
              <Drawer
                placement="top"
                width={250}
                onClose={onClose}
                open={visible}
              >
                <Menu
                  isProtectedPage
                  onClickMenu={handleToggleMenu}
                  user={user}
                />
              </Drawer>
            </>
          )}
        </div>
      )}
    </HeaderContainer>
  );
}

export default HeaderProtectedPage;
