import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const lang =
  window.localStorage && JSON.parse(window.localStorage.getItem('lang'));

const fallbackLng = lang || 'en';
const availableLanguages = ['id', 'en'];

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng,
    debug: false,
    supportedLngs: availableLanguages,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
