import { DownOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Grid, Typography } from 'antd';
import { logout } from 'features/Auth/authenticationSlice';
import { getInitialName } from 'helper/getInitialName';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const { useBreakpoint } = Grid;

function ProfileMenu({ user }) {
  const history = useHistory();
  const profileData = useSelector((state) => state.profile.getProfile);
  const dispatch = useDispatch();
  const { lg } = useBreakpoint();
  const { t } = useTranslation();

  const onClick = ({ key }) => {
    switch (key) {
      case '1':
        history.push('/profile/edit-data');
        break;
      case '2':
        dispatch(logout());
        setTimeout(() => {
          history.push('/sign-in');
        }, 700);
        break;
      default:
        break;
    }
  };

  const items = [
    {
      label: (
        <span style={{ fontSize: '16px', color: '#343434' }}>
          {t('menu_my_profile')}
        </span>
      ),
      key: '1',
    },
    {
      label: (
        <span id="signout" style={{ fontSize: '16px', color: '#343434' }}>
          {t('signout')}
        </span>
      ),
      key: '2',
    },
  ];

  return (
    <div className={`profile-wrap ${!lg && 'mobile'}`}>
      {lg ? (
        <Avatar
          className={`avatar-comp ${!lg && 'mobile'}`}
          size="large"
          gap={0}
          src={profileData?.avatar?.avatar_url}
          onClick={() => {
            history.push('/profile');
          }}
        >
          {getInitialName(user?.displayName)}
        </Avatar>
      ) : null}
      <div className={`bio-wrap ${!lg && 'mobile'}`}>
        {lg ? (
          <Dropdown menu={{ items, onClick }} trigger={['click']}>
            <a href="window.location" onClick={(e) => e.preventDefault()}>
              <Typography.Text
                ellipsis
                style={{
                  lineClamp: 1,
                  maxWidth: '200px',
                  height: '20px',
                }}
              >
                {t('nav_greet')}, {user?.displayName}
              </Typography.Text>
              <DownOutlined style={{ fontSize: '14px' }} />
            </a>
          </Dropdown>
        ) : (
          <div>
            <div
              className="greet center-div"
              style={{
                width: '200px',
              }}
            >
              <Typography.Text
                ellipsis
                style={{
                  color: '#000',
                  lineClamp: 1,
                  fontWeight: 700,
                  fontSize: '18px',
                  lineHeight: '28px',
                }}
              >
                {t('nav_greet')}, {user?.displayName}
              </Typography.Text>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default ProfileMenu;
